// import { IconButton, Stack } from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers";
// import React, { useEffect, useState } from "react";
// import Utils from "../../utils/utils";
// import { Clear } from "@mui/icons-material";

// const StartAndEndDateFilter = ({ onFilterChange, onRequestClear }) => {
//   const [filterState, setFilterState] = useState({});

//   useEffect(() => {
//     if (filterState.start_date && filterState.end_date) {
//       onFilterChange({
//         start_date: Utils.formatDate(filterState.start_date),
//         end_date: Utils.formatDate(filterState.end_date),
//       });
//     }
//     return () => { };
//   }, [filterState]);

//   return (
//     <Stack
//       gap={2}
//       direction={{ sm: "row", xs: "column" }}
//       sx={{ width: "100%" }}
//     >
//       <DatePicker
//         slotProps={{
//           textField: {
//             size: "small",
//             label: "Start Date",
//             fullWidth: true,
//           },
//         }}
//         onChange={(e) => setFilterState({ ...filterState, start_date: e })}
//         value={filterState?.start_date || null}
//         maxDate={filterState?.end_date}
//       />
//       <Stack direction="row" sx={{ width: "100%" }}>
//         <DatePicker
//           slotProps={{
//             textField: {
//               size: "small",
//               label: "End Date",
//               fullWidth: true,
//             },
//           }}
//           onChange={(e) => setFilterState({ ...filterState, end_date: e })}
//           value={filterState?.end_date || null}
//           minDate={filterState?.start_date}
//         />
//         {filterState.start_date && filterState.end_date && (
//           <IconButton
//             color="error"
//             onClick={() => {
//               onRequestClear();
//               setFilterState({});
//             }}
//           >
//             <Clear />
//           </IconButton>
//         )}
//       </Stack>
//     </Stack>
//   );
// };

// export default StartAndEndDateFilter;

import { IconButton, Stack } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import Utils from "../../utils/utils";
import { Clear } from "@mui/icons-material";
import { useRef } from "react";

const StartAndEndDateFilter = ({ onFilterChange, onRequestClear }) => {
  const [filterState, setFilterState] = useState({});

  useEffect(() => {
    if (filterState.start_date && filterState.end_date) {
      onFilterChange({
        start_date: Utils.formatDate(filterState.start_date),
        end_date: Utils.formatDate(filterState.end_date),
        start_time: Utils.formatDateInHours(filterState.start_date),
        end_time: Utils.formatDateInHours(filterState.end_date),
      });
    }
    return () => {};
  }, [filterState]);

  // useEffect(() => {
  //   ref.current = clearFilter;
  // }, [ref]);

  return (
    <Stack
      gap={2}
      direction={{ sm: "row", xs: "column" }}
      sx={{ width: "100%" }}
    >
      <DateTimePicker
        slotProps={{
          textField: {
            size: "small",
            label: "Start Date",
            fullWidth: true,
          },
        }}
        onChange={(e) => setFilterState({ ...filterState, start_date: e })}
        value={filterState?.start_date || null}
        maxDate={filterState?.end_date}
      />
      <Stack direction="row" sx={{ width: "100%" }}>
        <DateTimePicker
          slotProps={{
            textField: {
              size: "small",
              label: "End Date",
              fullWidth: true,
            },
          }}
          onChange={(e) => setFilterState({ ...filterState, end_date: e })}
          value={filterState?.end_date || null}
          minDate={filterState?.start_date}
        />
        {filterState.start_date && filterState.end_date && (
          <IconButton
            color="error"
            onClick={() => {
              onRequestClear();
              setFilterState({});
            }}
          >
            <Clear />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default StartAndEndDateFilter;
